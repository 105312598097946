import React, { useState } from 'react'
import { useStaticQuery, graphql, Link } from 'gatsby'
import styled from 'styled-components'
import ProgressiveImage from 'react-progressive-image'
import { motion } from 'framer-motion'
import GoogleMapReact from 'google-map-react'
import { mapTheme } from '../styles/map'
import { useLocation } from 'react-use'

import { GOOGLE_MAPS_KEY } from '../constants';
import { Layout } from '../components'
import { Fade } from '../utils'

import { container, padding, bgImage, bgIcon } from '../styles/global'
import { media, useBreakpoint } from '../styles/utils'
import {
    heading1,
    heading2,
    subheading2,
    heading3,
    links,
    body,
} from '../styles/type'
import { brown1, brown3, blue2, brown4, brown2, brown5 } from '../styles/colors'
import mapMarker from '../assets/images/icons/map-marker.svg'

const Contact = props => {
    const location = useLocation();
    const graphData = useStaticQuery(query).allWordpressInfopages.nodes[0]
    const data = JSON.parse(graphData.acf_json)

    const renderIntro = () => {
        return (
            <Intro>
                <Fade delay={800}>
                    <Container>
                        <Grid>
                            <Sidebar>
                                <Heading
                                    dangerouslySetInnerHTML={{
                                        __html: data.intro_heading,
                                    }}
                                />
                            </Sidebar>

                            <Main>
                                {useBreakpoint('phone') && (
                                    <MobileHeading
                                        dangerouslySetInnerHTML={{
                                            __html: data.details_heading,
                                        }}
                                    />
                                )}
                                <Description
                                    dangerouslySetInnerHTML={{
                                        __html: data.intro_text,
                                    }}
                                />
                            </Main>
                        </Grid>
                    </Container>
                </Fade>
            </Intro>
        )
    }

    const renderDetailsSidebar = () => {
        const social_links = data.details_social_links.map((item, i) => {
            return (
                <Item key={i}>
                    <Heading>{item.title}</Heading>
                    <ExternalLink href={item.url} target={'_blank'}>
                        Kardos Scanlan
                    </ExternalLink>
                </Item>
            )
        })

        return (
            <Sidebar>
                {!useBreakpoint('phone') && (
                    <Heading
                        dangerouslySetInnerHTML={{
                            __html: data.details_heading,
                        }}
                    />
                )}

                <Info>
                    <Phone>
                        <Heading>Telephone</Heading>
                        <ExternalLink href={`tel:${data.details_telephone}`}>
                            {data.details_telephone}
                        </ExternalLink>
                    </Phone>

                    <Address>
                        <Heading>Map Link</Heading>
                        <ExternalLink
                            href={`https://www.google.com/maps/search/?api=1&query=${encodeURI(
                                data.map.address
                            )}`}
                            target="_blank"
                            dangerouslySetInnerHTML={{
                                __html: data.details_address,
                            }}
                        />
                    </Address>

                    <Item>
                        <Heading>Email</Heading>
                        <ExternalLink href={`mailto:${data.details_email}`}>
                            {data.details_email}
                        </ExternalLink>
                    </Item>

                    {social_links}
                </Info>
            </Sidebar>
        )
    }

    const renderDetailsMain = () => {
        const { map } = data
        if (!location || !map) return

        const mapProps = {
            defaultZoom: 15,
            defaultCenter: {
                lat: parseFloat(map.lat),
                lng: parseFloat(map.lng),
            },
            bootstrapURLKeys: {
                key: GOOGLE_MAPS_KEY,
            },
            options: {
                styles: mapTheme,
            },
        }
        const markerProps = {
            lat: parseFloat(map.lat),
            lng: parseFloat(map.lng),
        }

        return (
            <Main>
                <Map>
                    <GoogleMapReact {...mapProps}>
                        <Marker {...markerProps} src={mapMarker} />
                    </GoogleMapReact>
                </Map>
            </Main>
        )
    }

    const renderDetails = () => {
        return (
            <Details>
                <Fade delay={1200}>
                    <Container>
                        <Grid>
                            {renderDetailsSidebar()}
                            {renderDetailsMain()}
                        </Grid>
                    </Container>
                </Fade>
            </Details>
        )
    }

    const renderJoinSidebar = () => {
        return (
            <Sidebar>
                {!useBreakpoint('phone') && (
                    <Heading
                        dangerouslySetInnerHTML={{
                            __html: data.join_heading,
                        }}
                    />
                )}

                <Info>
                    <Phone>
                        <Heading>Telephone</Heading>
                        <ExternalLink href={`tel:${data.join_telephone}`}>
                            {data.join_telephone}
                        </ExternalLink>
                    </Phone>

                    <Item>
                        <Heading>Email</Heading>
                        <ExternalLink href={`mailto:${data.join_email}`}>
                            {data.join_email}
                        </ExternalLink>
                    </Item>
                </Info>
            </Sidebar>
        )
    }

    const renderJoinMain = () => {
        return (
            <Main>
                {useBreakpoint('phone') && (
                    <MobileHeading
                        dangerouslySetInnerHTML={{
                            __html: data.join_heading,
                        }}
                    />
                )}
                <Description
                    dangerouslySetInnerHTML={{
                        __html: data.join_text,
                    }}
                />
            </Main>
        )
    }

    const renderJoin = () => {
        return (
            <Join>
                <Fade 
                    delay={1400}
                >
                    <Container>
                        <Grid>
                            {renderJoinSidebar()}
                            {renderJoinMain()}
                        </Grid>
                    </Container>
                </Fade>
            </Join>
        )
    }

    return (
        <Layout
            meta={data && data.seo}
            desktopHeaderTheme={'dark'}
        >
            {renderIntro()}
            {renderDetails()}
            {renderJoin()}
        </Layout>
    )
}

// Shared

const Heading = styled.h1``
const MobileHeading = styled.h1`
    ${heading3};
`
const Subheading = styled.h2``
const Description = styled.div``
const ExternalLink = styled.a`
    display: inline-block;
`

const Image = styled(ProgressiveImage)`
    overflow: hidden;
`

const BGImage = styled.div`
    background-image: url(${props => props.image});
    ${bgImage};
    transition: opacity 0.45s ease;
`

// Layout

const Grid = styled.div``
const Sidebar = styled.div``
const Main = styled.div``
const Container = styled.div`
    ${container}
    ${padding}

    ${Grid} {
        display: flex;
        max-width: 100%;

        ${media.phone`
            flex-direction: column;
        `}
    }

    ${Sidebar}, ${Main} {
        box-sizing: border-box;
    }

    ${Sidebar} {
        width: 33.33%;

        ${media.phone`
            order: 9;
            width: 100%;
        `}
    }

    ${Main} {
        width: 66.66%;

        ${media.phone`
            width: 100%;
        `}
    }
`

// Intro

const Intro = styled.div`
    padding-top: 190px;
    background-color: ${brown3};

    ${media.tablet`
        padding-top: 140px;
    `}

    ${media.phone`
        padding-top: 100px;
    `}

    ${Sidebar} {
        padding-bottom: 160px;
        border-right: 1px solid white;

        ${media.tabletLandscape`
            padding-bottom: 120px;
        `}

        ${media.phone`
            order: -9;
            padding-bottom: 90px;
            border-right: none;
        `}
    }

    ${Main} {
        padding-left: 20px;

        ${media.phone`
            padding-left: 0;
        `}
    }

    ${Heading} {
        ${heading1};
        line-height: 1;
    }

    ${Description} {
        width: 580px;
        max-width: 100%;
        ${heading2};

        ${media.phone`
            margin-top: 32px;
        `}

        a {
            color: ${blue2};
            transition: color 0.15s ease;
            white-space: nowrap;

            &:hover {
                color: ${brown2};
            }
        }
    }
`

// Info (Details and Join sections sidebars)

const Phone = styled.div``
const Address = styled.div``
const Item = styled.div``
const Info = styled.div`
    margin-top: 100px;

    ${media.tabletLandscape`
        margin-top: 48px;
    `}

    ${Phone}, ${Address}, ${Item} {
        padding-top: 16px;
        padding-bottom: 24px;
        border-top: 2px solid white;

        ${Heading} {
            ${subheading2};
        }
    }

    ${Phone}, ${Address} {
        ${ExternalLink} {
            margin-top: 8px;
            ${heading2};
            color: ${brown5};
            transition: color 0.15s ease;

            ${media.phone`
                font-size: 24px;
            `}

            &:hover {
                color: ${brown1};
            }
        }
    }

    ${Item} ${ExternalLink} {
        ${links};
        display: inline;
        line-height: 1.6;
        transition: border-color 0.15s ease, color 0.15s ease;

        ${media.phone`
            font-size: 12px;
            border-bottom-color: transparent;
        `}

        &:hover {
            color: ${brown1};
            border-color: ${brown1};
        }
    }
`

// Details Section

const Marker = styled.img`
    position: absolute;
    top: 50%;
    left: 50%;
    width: 40px;
    height: auto;
    transform: translate(-50%, -50%);
`
const Map = styled.div``
const Details = styled.div`
    padding-top: 20px;
    padding-bottom: 80px;
    background-color: ${brown3};

    ${media.phone`
        padding-top: 28px;
        padding-bottom: 20px;
    `}

    ${Sidebar} {
        padding-right: 90px;

        ${media.tabletLandscape`
            padding-right: 60px;
        `}

        ${media.phone`
            padding-right: 0;
        `}

        /* desktop only */
        > ${Heading} {
            ${heading3};
        }
    }

    ${Map} {
        width: 100%;
        height: 800px;
        background-color: ${brown4};

        ${media.tabletLandscape`
            height: 600px;
        `}

        ${media.tablet`
            height: 500px;
        `}

        ${media.phone`
            height: 300px;
        `}
    }
`

// Join Section

const Join = styled.div`
    padding-top: 80px;
    padding-bottom: 160px;
    background-color: ${brown4};

    ${media.tabletLandscape`
        padding-bottom: 120px;
    `}

    ${media.phone`
        padding-top: 44px;
        padding-bottom: 38px;
    `}

    ${Sidebar} {
        padding-right: 90px;

        ${media.tabletLandscape`
            padding-right: 60px;
        `}

        ${media.phone`
            padding-right: 0;
        `}

        > ${Heading} {
            ${heading3};
        }
    }

    ${Main} ${Description} {
        width: 600px;
        max-width: 100%;
        ${body};

        ul {
            margin-top: 2.5em;
        }

        ${media.phone`
            margin-top: 32px;
        `}
    }
`

export const query = graphql`
    query {
        allWordpressInfopages(filter: { slug: { eq: "contact" } }) {
            nodes {
                acf_json
            }
        }
    }
`

export default Contact
